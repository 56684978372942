export const API_BASE_URL = "http://165.22.44.27:4000"

export const ApiEndPoints = {
    AUTH: {
        login: `${API_BASE_URL}/api/v1/auth/login`,
        me: `${API_BASE_URL}/api/v1/auth/me`
    },
    MEDICAL_SPECIALIZATION: {
        list: `${API_BASE_URL}/api/v1/medical-specialization`,
        create: `${API_BASE_URL}/api/v1/medical-specialization`,
        edit: (id) => `${API_BASE_URL}/api/v1/medical-specialization/${id}`,
        delete: (id) => `${API_BASE_URL}/api/v1/medical-specialization/${id}`,
    },

}