import React, { Suspense } from 'react'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import FallbackSpinner from './@core/components/spinner'

import AuthGuard from 'src/@core/components/auth/AuthGuard'
import UserLayout from './layouts/UserLayout'
import BlankLayout from './@core/layouts/BlankLayout'
// import BlankLayoutWithAppBar from './@core/layouts/BlankLayoutWithAppBar'
import AclGuard from './@core/components/auth/AclGuard'
import GuestGuard from './@core/components/auth/GuestGuard'
import { defaultACLObj } from './configs/acl'
import AuthLayout from './layouts/AuthLayout'

const HomePage = React.lazy(() => import('./pages/home'))
const LoginPage = React.lazy(() => import('./pages/login'))
const MedicalSpecializationPage = React.lazy(() => import('./pages/medical-specialization'))
const SubscriptionPlansPage = React.lazy(() => import('./pages/subscription-plans'))
const SettingsPage = React.lazy(() => import('./pages/settings'))

const Page401 = React.lazy(() => import('./pages/401'))
const Page404 = React.lazy(() => import('./pages/404'))

function App() {
  const aclAbilities = defaultACLObj

  return (
    <Suspense fallback={<FallbackSpinner />}>
      <Routes>
        <Route element={<BlankLayout><Outlet /></BlankLayout>}>
          <Route path='/401' element={<Page401 />} />
          <Route path='/404' element={<Page404 />} />

          <Route path='/' element={<HomePage />}></Route>
        </Route>
        {/* If no route found redirect it to --> /404 */}
        <Route path='*' element={<Navigate to='/404' replace />} />

      </Routes>
    </Suspense>
  );
}

export default App;
